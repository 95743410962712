.hero-image {
	margin: 1.25em 1.25em 0em 1.25em;
}

.site-content {
	margin-top: 4.25em;
	
	hr {
		width: 150px;
	}
}

.row {
	padding: 4em 7%;

	@media (max-width: $screen-xs-max) {
		padding: 2em 0.5em;
	}

	&.buch {
		img {
			margin-bottom: 3em;
			margin-top: 0.25em;
		}
	}

	&--green {
		background-color: $green;
		margin: 0em 1.5em;
		border-radius: 5px;

		.bvm--logo {
			p {
				text-align: left;
				float: left;

				@media (min-width: $screen-sm-min) {
					width: 75%;
				}
			}

			img {
				float: right;
				border: 10px solid $white;

				@media (max-width: 1500px) {
					display: block;
					float: left;
					margin-bottom: 1em;
				}

				@media (max-width: $screen-sm-max) {
					display: block;
					float: none;
					margin-bottom: 1em;
				}
			}
		}

		p {
			color: $white;
			margin-bottom: 2em;
		}

		svg {
			fill: white;
		}

		.btn {
			background-color: $white;
			margin: 0 0.5em;

			@media (max-width: $screen-xs-max) {
				margin-bottom: 0.5em;
			}


			div {
				border-color: $green;
				color: $green;
			}

			&:hover {

				div {
					color: white;
					border-color: white;
				}
			}
		}
	}

	a {
		color: inherit;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}
}

.center {
	margin: auto;
	text-align: center;
	display: block;
	float: none;
}

.btn {
	display: inline-block;
	font-family: $font-family-heading;
    background-color: $lightgrey;
    padding: 0.5em 0.5em;
    border-radius: 5px;
    margin-top: 2em;
    text-decoration: none !important;

    @media (max-width: $screen-xs-max) {
    	margin-bottom: 0.5em;
    }


    &--flr {
    	float: right;
    }

	div {
		text-transform: uppercase;
		padding: 0.75em 0.5em 0.5em;;
		border-radius: 5px;
		border: 2px solid $white;
		color: $white;
	}

	&:hover {
		text-decoration: none;
		background-color: $green;
	}
}

.kunden {
	margin-left: -15px;
	margin-right: -15px;

	img {
		margin-bottom: 1em;
		-webkit-filter: grayscale(1);
		-moz-filter: grayscale(1);
		-ms-filter: grayscale(1);
		-o-filter: grayscale(1);
		filter: grayscale(1);
    	opacity: 0.5;
	}
}

img {
	border-radius: 5px;
}

body {
	background-image: linear-gradient(transparent 1px, transparent 2px),linear-gradient(90deg, transparent 1px, transparent 2px),linear-gradient(#f3f3f3 1px, transparent 1px),linear-gradient(90deg, #f3f3f3 1px, transparent 1px);
	background-size: 100px 100px, 100px 100px, 10px 10px, 10px 10px;
}

.ref {
	@media(max-width: $screen-xs-max) {
		padding-left: 0.5em;
		padding-right: 0.5em;

		img {
			display: none;
		}
	}

	margin-top: 2em;

	.ref-liste {
		float: none;
		margin: auto;

		ul {
			list-style-type: none;
			color: $green;

			@media (max-width: $screen-xs-max) {
				padding-left: 0;
			}

			li {
				margin-bottom: 3em;



				&:last-child {
					margin-bottom: 0;
				}

				h3 {
					margin-bottom: 0;
					line-height: 1.6em;

					@media (max-width: $screen-xs-max) {
						text-align: left;
					}
				}

				blockquote {
					color: $grey;
					font-size: $font-size-base;
					font-style: italic;
					padding: 0 1em;
					margin-top: -0.5em;
				}
			}
		}
	}
}

.response {
	padding: 1em;
	border-radius: 5px;

	p {
		color: $white;
	}
	
	&.send-success {
		background-color: $green;
	}

	&.send-error {
		background-color: $orange;
	}
}

@media (max-width: $screen-xs-max) {
	img {
		margin-bottom: 1.5em;
	}
}

.leistungsgebiete {
	margin-bottom: 3em;
	display: table;
}

.n-p-b {
	padding-bottom: 0em;
}