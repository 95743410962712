.main-navigation {
    background: none;

    .menu-item-current {
    	a {
    		color: $white !important;
    		background-color: $green;
    		padding: 0.5em 1em 1.4em;
    		border-radius: 5px 5px 0px 0px;
    		margin: -0.5em -1em -1.5em;

            @media (max-width: $screen-xs-max) {
                border-radius: 5px;
                margin: 0;
                padding: 0.5em;
            }
    	}
    }
}

.content-overflow {
    padding-bottom: 0.6em;
}

@media (max-width: $screen-xs-max) {
    
    .content-overflow {
        background-color: $white;

        .menu-item {
            margin-bottom: 1em;
        }    
    }

    .off-canvas__toggle {
        padding: 15px;
    }
}