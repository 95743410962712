.site-footer {
	@media (max-width: $screen-xs-max) {
		padding-left: 0.75em;
		padding-right: 0.75em;
	}
	
	@media (min-width: $screen-sm-min) {
		h2 {
			margin-bottom: 2em;
		}
	}

	background-color: $lightgrey;

	p {
		color: $white;
	}

	.bvm {

		img {
			width: 50%;
			border: 5px solid white;
		}

		@media (min-width: $screen-sm-min) {
			margin-top: 5.5em;
		}

		@media (max-width: $screen-xs-max) {
			margin-bottom: 2em;
		}
	}

	.formular {
		@media (min-width: $screen-sm-min) {
			border-left: 2px solid $white;
		}

		.row {
			padding: 0 !important;

			label {
				margin-bottom: 1em;

				span {
					text-transform: uppercase;
					color: $white;
				}
			}

			.btn {
				float: right; 

				&--orange {
					background-color: $orange;

					&:hover {
						background-color: $green;
					}
				}
			}
		}
	}
}

.footer-leiste  {

	padding-top: 1em;
	padding-bottom: 0.25em;
	background-color: $grey;
	text-transform: uppercase;

	p,
	a {
		color: $lightgrey;
		font-size: 0.75em;

		 @media(max-width: $screen-xs-max) {
		 	display: block;
		 	text-align: center;
		 	margin-bottom: 1em;
		 }
	}

	.footer-menu {
		ul {
			justify-content: space-evenly;

			@media (min-width: $screen-sm-min) {
				margin-top: -0.3em;
			}
		}
	}

	ul {
		list-style-type: none;

		a:hover {
			color: $white;
		}
	}
}