@font-face {
	font-family: 'pt-caption';
	src: url( '../fonts/pt-caption-webfont.woff' ) format( 'woff' );
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'dehuti';
	src: url( '../fonts/dehuti-book-webfont.woff' ) format( 'woff' );
	font-weight: normal;
	font-style: normal;
}