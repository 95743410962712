$page-header-border-color: transparent;

$green: #799C12;
$grey: #474746;
$orange: #E47823;
$darkgreen: #548526;
$lightgrey: #999A98;

$font-size-base: 17px;
$font-size-h1: 34px;
$font-size-h2: 30px;
$font-size-h3: 18px;
$font-size-h4: 16px;

$font-family-base: 'pt-caption';
$font-family-heading: 'dehuti';
