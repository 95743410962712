h1,
.h,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
	font-family: $font-family-heading;
	color: $green;
	text-transform: uppercase;
	margin-bottom: 15px;
	margin-top: 0;

	@media (max-width: $screen-xs-max) {
		text-align: center;
	}
}

h1,
.h1 {
	font-size: $font-size-h1;
	margin-bottom: 25px;
}

.bott-2 {
	@media ( min-widht: $screen-sm-min) {
		h1 {
			margin-bottom: 2em;
		}
	}
}

h2,
.h2 {
	font-size: $font-size-h2;
}

h3,
.h3 {
	font-size: $font-size-h3;
	margin-bottom: 0;
	margin-top: 1.5em;
}

h4,
.h4 {
	font-size: $font-size-h4;
	margin-bottom: 0;
	margin-top: 1.5em;
}

.head-white {
	color: $white;
}

p, li {
	line-height: 1.8em;
	color: $grey;
	font-family: $font-family-base;
}