.site-header {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 100;
	background-image: linear-gradient(transparent 1px, transparent 2px),linear-gradient(90deg, transparent 1px, transparent 2px),linear-gradient(#f3f3f3 1px, transparent 1px),linear-gradient(90deg, #f3f3f3 1px, transparent 1px);
	background-size: 100px 100px, 100px 100px, 10px 10px, 10px 10px;
	background-color: $white;
	
	.row {
		padding-top: 0.5em;
		padding-bottom: 0.5em;
   		border-bottom: 2px solid $green;

		.main-navigation {

			ul {
				list-style-type: none;

				a {
					text-transform: uppercase;
					color: $grey;

					&:hover {
						color: $green;
					}

				}
			}
		}
		@media (max-width: $screen-md-max) {
			padding-left: 0.5em;
			padding-right: 0.5em;
			padding-bottom: 0.2em;

			a {
				font-size: 14px;
			}
		}
	}

	.logo {

		@media (min-width: $screen-md-min) {
			width: 300px;
		   	padding-top: 0.5em;
		}

		@media (max-width: $screen-sm-max) {
			width: 100%;
			padding-top: 0.75em;
		}

		@media (max-width: $screen-xs-max) {
			    width: 300px;
			    padding: 5px 0px;
			    float: right;
			    margin-bottom: 0px;
		}
		@media (max-width: 400px) {
			    width: 200px;
		}
	}
}