.off-canvas {
	transition: transform $transition-duration, background-color $transition-duration;
	width: 80%;
	position: absolute;
	top: 0;
	left: 0;
	transform: translateX( -100% );
	z-index: 10;

	@media ( min-width: $screen-sm-min ) {
		position: static;
		width: auto;
		transition: none;
	}

	.menu-open & {
		transform: none;
		z-index: 10;

	}

	&.nav {
		@media ( min-width: $screen-sm-min ) {
			transform: none;
		}
	}

	&__toggle {
		position: absolute !important;
		top: 0;
		left: 100%;
		appearance: none;
		border: none;
		background: $white;
		transition: opacity $transition-duration, margin $transition-duration;
		text-align: center;
		padding: $grid-gutter-width / 3;
		z-index: 1;

		.menu-open & {
			margin-left: 0;
		}
	}

	> * {
		position: relative;
	}
}

.content-overflow {
	max-height: 90vh;
	overflow-y: auto;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
	padding: $grid-gutter-width / 2;

	@media ( min-width: $screen-sm-min ) {
		overflow: visible;
		max-height: none;
	}
}
